// import ga from 'vue-ga';
import { createRouter, createWebHistory } from 'vue-router';
import config from '../.env';

import Home from '../views/home.vue';

const routes = [
  // prettier-ignore
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/qr/:id',
  //   name: 'QR',
  //   // prettier-ignore
  //   component: () => import( /* webpackChunkName: "dashboard" */ '../views/A1C.vue')
  // },
  {
    path: '/qr/:qr?',
    name: 'Qr',
    // prettier-ignore
    // component: () => import( /* webpackChunkName: "dashboard" */ '../views/instructions-new.vue')
    component: () => import( /* webpackChunkName: "dashboard" */ '../views/qr.vue'),
  },
  {
    path: '/login',
    name: 'login-by-link',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "dashboard" */ '../views/login-by-link.vue'),
  },
  // {
  //   path: '/qr/beta/:qr',
  //   name: 'Instructions Beta',
  //   // prettier-ignore
  //   component: () => import( /* webpackChunkName: "dashboard" */ '../views/instructions-new.vue')
  // },
  {
    path: '/profile',
    name: 'profile',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "dashboard" */ '../views/profile.vue'),
  },

  // //////////////
  // TV APP
  // //////////////
  // {
  //   path: '/tv/products/user/:tvUserId',
  //   name: 'Products',
  //   // prettier-ignore
  //   component: () => import(  '../views/tv/products-v2.vue'),
  // },
  {
    path: '/tv/summary',
    name: 'summary',
    // prettier-ignore
    component: () => import(  '../views/tv/summary.vue'),
  },
  {
    path: '/tv/address',
    name: 'address',
    // prettier-ignore
    component: () => import(  '../views/tv/address.vue'),
  },
  {
    path: '/tv/change-order-details/:tvUserId',
    name: 'ChangeOrderDetails',
    // prettier-ignore
    component: () => import(  '../views/tv/change-order-details.vue'),
  },
  {
    path: '/tv/checkout/:sessionId',
    name: 'StripeCheckout',
    // prettier-ignore
    component: () => import(  '../views/tv/checkout.vue'),
  },
  {
    path: '/tv/payment-response/success',
    name: 'paymentSuccess',
    // prettier-ignore
    component: () => import(  '../views/tv/payment-response.vue'),
  },
  {
    path: '/tv/payment-response/failure',
    name: 'paymentFailure',
    // prettier-ignore
    component: () => import(  '../views/tv/payment-response.vue'),
  },
  {
    path: '/sample/blood/tube',
    name: 'tube',
    component: () => import('../components/instructionsNew/blood-tube.vue'),
  },
  {
    path: '/sample/blood/card',
    name: 'card',
    component: () => import('../components/instructionsNew/blood-card.vue'),
  },
  {
    path: '/sample/genetic',
    name: 'genetic',
    component: () => import('../components/instructionsNew/Genetic.vue'),
  },
  {
    path: '/sample/urine',
    name: 'urine',
    component: () => import('../components/instructionsNew/Urine.vue'),
  },
  {
    path: '/sample/saliva',
    name: 'saliva',
    component: () => import('../components/instructionsNew/Saliva.vue'),
  },
  {
    path: '/sample/Stool',
    name: 'Stool',
    component: () => import('../components/instructionsNew/stool.vue'),
  },
  //sample type instructions
  {
    path: '/sample-instructions/genetic',
    name: 'sampleInstructions',
    component: () => import('../views/sample-type-instructions.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/forgot-password.vue'),
  },
  // new TV
  {
    path: '/tv/products/user/:tvUserId',
    name: 'TV-Products',
    component: () => import('../views/tv/new-interface/products.vue'),
  },
    ///////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////// US BioTech LIMS ////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  {
    path: '/orders/lims/page/:page?',
    name: 'lims',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/lims/lims.vue'),
  },

  {
    path: '/orders/lims/address/checkout',
    name: 'lab_address',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/lims/lab_address.vue'),
  },
  // {
  //   path: '/orders/lims/orders/:page?',
  //   name: 'lab_orders',
  //   // prettier-ignore
  //   component: () => import( /* webpackChunkName: "users" */ '../views/lims/lims_orders.vue'),
  // },
  {
    path: '/thankyou',
    name: 'thankyou_page',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/lims/thankyou.vue'),
  },
  {
    path: '/payment-response/:status',
    name: 'payment_response',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "users" */ '../views/lims/payment-response-lims.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // await store.restored;
  if (!document.querySelector('#rxhometest-script')) {
    window.addEventListener('load', () => {
      const scriptTag = document.createElement('script');
      scriptTag.src = `${config.mixpanelOriginUrl}/mixpanel-rxhometest.js`;
      scriptTag.id = 'rxhometest-script';
      scriptTag.type = 'text/javascript';
      scriptTag.async = true;

      console.log('load', `${config.mixpanelOriginUrl}/mixpanel-rxhometest.js`);

      const existingScriptTag = document.querySelector('#rxhometest-script');
      if (existingScriptTag) {
        document.body.removeChild(existingScriptTag);
      }
      document.body.appendChild(scriptTag);
    });
  } else {
    const scriptTag = document.createElement('script');
    scriptTag.src = `${config.mixpanelOriginUrl}/mixpanel-rxhometest.js`;
    scriptTag.id = 'rxhometest-script';
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;

    console.log(`${config.mixpanelOriginUrl}/mixpanel-rxhometest.js`);

    const existingScriptTag = document.querySelector('#rxhometest-script');
    if (existingScriptTag) {
      document.body.removeChild(existingScriptTag);
    }
    document.body.appendChild(scriptTag);
  }
  next();
});

export default router;

<template>
  <svg class="inline-block fill-current" viewBox="0 0 558.957 558.957">
    <g>
      <polygon id="next-btn" :name="name" points="462.745,0 96.212,279.479 462.745,558.957 462.745,419.221 278.713,279.479 462.745,139.738   " />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    name: String,
  },
  data() {
    return {};
  },
};
</script>
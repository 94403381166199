import Vue from 'vue';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
// import moment from 'moment';
import config from '../.env';

export const main = defineStore('main', () => {
  let toastMsg = ref({ msg: '', type: '' });
  let qrUrl = config.qrUrl;
  let adminUrl = config.adminUrl;
  let user = ref(useLocalStorage('user', {}));
  let token = ref(useLocalStorage('token', ''));
  const tvToken = 'a29288cf-12c7-40ae-a340-a97bf825cb75';
  const stripeKey = 'pk_test_6KGf6WilZ4g1uaOTFjkeGlQb00wDSznfSy';
  let currQrCode = ref(useLocalStorage('currQrCode', ''));
  let timeOutId = ref(undefined);
  let isSideMenuOpen = false;
  let isLoginModal = false;
  let currBatch = ref(useLocalStorage('batch', undefined));
  let isVitalleoOrder = ref(useLocalStorage('vitalleoOrder', false));
  let qrRes = ref(useLocalStorage('qrRes', {}));
  let isAffiliate = ref(useLocalStorage('isAffiliate', false));
  let isGuideVisited = ref(useLocalStorage('isGuideVisited', false));
  let qrScreenTabIndex = ref(useLocalStorage('qrScreenTabIndex', 0));
  let tvUserToken = ref(useLocalStorage('tvUserToken', undefined));
  let tvUserId = ref(useLocalStorage('tvUserId', ''));
  let tvUser = ref(useLocalStorage('tvUser', {}));
  let cart = ref(useLocalStorage('cart', []));
  let cartMap = ref(useLocalStorage('cartMap', {}));
  let location = ref(
    useLocalStorage('location', {
      latitude: undefined,
      longitude: undefined,
    }),
  );
  let shippingAddress = ref(useLocalStorage('shippingAddress', {}));
  let shippingAddressStatus = ref(useLocalStorage('shippingAddressStatus', ''));
  let billingAddress = ref(useLocalStorage('billingAddress', {}));
  let permissions = {
    clinic: 27,
    clinic_orders: 29,
    doctor_npi_field: 31,
    qr_scanner: 30,
    vitalleo: 25,
    vitalleo_orders: 32,
    vitalleo_user: 28,
    patient_email: 33,
    return_tracking_field: 34,
  };
  let keys = {
    ENTER: 13,
    RIGHT: 39,
    LEFT: 37,
    UP: 38,
    DOWN: 40,
    BACK: [8, 461],
  };
  let deviceType = ref(useLocalStorage('deviceType', ''));

  //////LIMS///////
  let order = ref(useLocalStorage('order', {}));
  let searchKey = ref(useLocalStorage('key', ''));

  function setUser(usr) {
    user.value = usr;
  }

  function setToken(val) {
    token.value = val;
  }

  function setBatch(val) {
    currBatch.value = val;
  }

  function setVitalleoOrder(isBool) {
    isVitalleoOrder.value = isBool;
  }

  function setQrResponse(res) {
    qrRes.value = res;
  }

  function setLocation(val) {
    location.value = val;
  }

  function setBillingAddress(address) {
    billingAddress.value = address;
  }

  function setShippingAddressStatus(status) {
    shippingAddressStatus.value = status;
  }

  function setShippingAddress(address) {
    shippingAddress.value = address;
  }

  function setAffiliate(boolean) {
    isAffiliate.value = boolean;
  }

  function setQrScreenTabIndex(index) {
    qrScreenTabIndex.value = index;
  }

  function setTVUserToken(token) {
    tvUserToken.value = token;
  }

  function setTvUserId(id) {
    tvUserId.value = id;
  }

  function setTvUser(user) {
    tvUser.value = user;
  }

  function setCart(prod) {
    cartMap.value[prod.title] = cart.value.length;
    cart.value.push(prod);
  }

  function deleteCartItem(title) {
    const index = cart.value.findIndex((item) => item.title === title);
    delete cartMap.value.title;
    cart.value.splice(index, 1);
    cart.value.forEach((cartItem, index) => {
      cartMap.value[cartItem.title] = index;
    });
  }

  function clearCart() {
    cart.value = [];
    cartMap.value = {};
  }

  function updateCartQty({ title, qty }) {
    const index = cart.value.findIndex((item) => item.title === title);
    cart.value[index].qty = qty;
    cart.value[index].newPrice = parseFloat(cart.value[index].sellPrice) * parseInt(cart.value[index].qty);
    console.log('cart item:', cart.value[index]);
  }

  function setGuideVisitedStatus(bool) {
    isGuideVisited.value = bool;
  }

  function setCurrQrCode(qr) {
    currQrCode.value = qr;
  }

  function setSearchKey(key) {
    searchKey.value = key;
    // console.log('pinia searchKey', searchKey.value);
  }

  function addToOrder(obj) {
    const key = obj.key;
    const value = obj.value;
    order.value[key] = value;
    console.log('addToOrder', order.value);
  }

  function toast(msg, type) {
    toastMsg.value.msg = msg;
    toastMsg.value.type = type;
    let delayInSeconds = undefined;
    if (type === 'success') {
      delayInSeconds = 2000;
    } else {
      delayInSeconds = 7000;
    }
    console.log('toast delayInSeconds', delayInSeconds);
    timeOutId.value = setTimeout(() => {
      toastMsg.value.msg = '';
      toastMsg.value.type = '';
      clearTimeout(timeOutId.value);
    }, delayInSeconds);
  }

  function removeToastMsg() {
    toastMsg.value.msg = '';
    clearTimeout(timeOutId.value);
  }

  function getDateWithTime(dateTimeStringTZ) {
    if (dateTimeStringTZ) {
      let date = new Date(dateTimeStringTZ).toString();
      return date.substring(4, 21);
    }
    return 'No Date';
  }

  return {
    toastMsg,
    qrUrl,
    adminUrl,
    user,
    token,
    tvToken,
    stripeKey,
    isSideMenuOpen,
    isLoginModal,
    permissions,
    isVitalleoOrder,
    location,
    billingAddress,
    shippingAddress,
    shippingAddressStatus,
    isAffiliate,
    qrScreenTabIndex,
    tvUserToken,
    tvUserId,
    tvUser,
    cart,
    cartMap,
    isGuideVisited,
    keys,
    qrRes,
    order,
    currQrCode,

    toast,
    removeToastMsg,
    getDateWithTime,
    setUser,
    setSearchKey,
    setToken,
    setBatch,
    setVitalleoOrder,
    setQrResponse,
    setLocation,
    setBillingAddress,
    setShippingAddress,
    setShippingAddressStatus,
    setAffiliate,
    setQrScreenTabIndex,
    setTVUserToken,
    setTvUserId,
    setTvUser,
    clearCart,
    setCart,
    deleteCartItem,
    updateCartQty,
    setGuideVisitedStatus,
    setCurrQrCode,
    addToOrder,
    deviceType,
  };
});

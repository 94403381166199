import App from './App.vue';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { main } from './store/index';

const app = createApp(App);
app.use(createPinia());

const directives = {
  customClickDirective(app) {
    app.directive('in-click', {
      mounted(el, binding) {
        if (['android', 'sony'].includes(main().deviceType)) {
          return;
        }
        const handler = binding.value;
        if (typeof handler === 'function') {
          el.addEventListener('click', () => {
            handler();
          });
        }
      },
      beforeUnmount(el, binding) {
        if (['android', 'sony'].includes(main().deviceType)) {
          return;
        }
        const handler = binding.value;
        if (typeof handler === 'function') {
          el.removeEventListener('click', handler);
        }
      },
    });
  },

  // customClickDirectiveOnce(app) {
  //   app.directive('in-click-once', {
  //     mounted(el, binding) {
  //       if (['android', 'sony'].includes(mainStore().deviceType)) {
  //         return;
  //       }

  //       const handler = binding.value;
  //       let clicked = false; // Track whether the element has been clicked

  //       const clickHandler = () => {
  //         if (!clicked && typeof handler === 'function') {
  //           handler();
  //           clicked = true;
  //         }
  //       };

  //       el.addEventListener('click', clickHandler);
  //       el.__clickHandler = clickHandler; // Store the handler function on the element
  //     },

  //     beforeUnmount(el) {
  //       if (['android', 'sony'].includes(mainStore().deviceType)) {
  //         return;
  //       }

  //       const clickHandler = el.__clickHandler;
  //       if (typeof clickHandler === 'function') {
  //         el.removeEventListener('click', clickHandler);
  //         delete el.__clickHandler; // Remove the stored handler function
  //       }
  //     },
  //   });
  // },

  customMouseoverDirective(app) {
    app.directive('in-mouseover', {
      mounted(el, binding) {
        if (['android', 'sony'].includes(main().deviceType)) {
          return;
        }
        const handler = binding.value;
        if (typeof handler === 'function') {
          el.addEventListener('mouseover', () => {
            handler();
          });
        }
      },
      beforeUnmount(el, binding) {
        if (['android', 'sony'].includes(main().deviceType)) {
          return;
        }
        const handler = binding.value;
        if (typeof handler === 'function') {
          el.removeEventListener('mouseover', handler);
        }
      },
    });
  },

  // customMousemoveDirective(app) {
  //   app.directive('in-mousemove', {
  //     mounted(el, binding) {
  //       if (['android', 'sony'].includes(mainStore().deviceType)) {
  //         return;
  //       }
  //       const handler = binding.value;
  //       if (['android', 'sony'].includes(mainStore().deviceType)) {
  //         return;
  //       }
  //       if (typeof handler === 'function') {
  //         el.addEventListener('mousemove', () => {
  //           handler();
  //         });
  //       }
  //     },
  //     beforeUnmount(el, binding) {
  //       if (['android', 'sony'].includes(mainStore().deviceType)) {
  //         return;
  //       }
  //       const handler = binding.value;
  //       if (['android', 'sony'].includes(mainStore().deviceType)) {
  //         return;
  //       }
  //       if (typeof handler === 'function') {
  //         el.removeEventListener('mousemove', handler);
  //       }
  //     },
  //   });
  // },

  // customMouseleaveDirective(app) {
  //   app.directive('in-mouseleave', {
  //     mounted(el, binding) {
  //       if (['android', 'sony'].includes(mainStore().deviceType)) {
  //         return;
  //       }
  //       const handler = binding.value;
  //       if (typeof handler === 'function') {
  //         el.addEventListener('mouseleave', () => {
  //           handler();
  //         });
  //       }
  //     },
  //     beforeUnmount(el, binding) {
  //       if (['android', 'sony'].includes(mainStore().deviceType)) {
  //         return;
  //       }
  //       const handler = binding.value;
  //       if (typeof handler === 'function') {
  //         el.removeEventListener('mouseleave', handler);
  //       }
  //     },
  //   });
  // },
};

export default directives;

module.exports = {
  validation: {
    setAllToFalseTemporarily(formFields) {
      // before final validation - set all to false
      // because initially every field is set to isValid to not show error msg
      formFields.forEach((field) => {
        if (!field.isDisabled) {
          field.isValid = false;
        }
      });
    },
    setFieldsValid(formFields, formData) {
      for (let i = 0; i < formFields.length; i += 1) {
        const field = formFields[i];
        const key = field.key;
        const value = formData[key];
        // console.log('regex', key, value, field, field.regex);
        if (Array.isArray(value)) {
          if (value.length > 0) {
            field.isValid = true;
          } else {
            field.isValid = false;
          }
        } else if (typeof value === 'object' && JSON.stringify(value) === '{}' && field.isRequired) {
          field.isValid = false;
        } else if (field.regex.test(value) && field.isRequired) {
          console.log('regex', key, value, field.regex);
          field.isValid = true;
        } else if (!field.isRequired) {
          field.isValid = true;
        } else {
          field.isValid = false;
        }
      }
    },
    isAllFieldsValid(formFields) {
      for (let i = 0; i < formFields.length; i += 1) {
        const field = formFields[i];
        if (!field.isValid) {
          return false;
        }
      }
      return true;
    },
    isSingleFieldValid(field, value) {
      console.log(`field, value`, field, value);
      if (value === 'No') {
        value = 'Not'; // regex.test("No") is always false | this wont affect the actual value going to be saved
      }

      if (Array.isArray(value)) {
        if (value.length > 0) {
          field.isValid = true;
        } else {
          field.isValid = false;
        }
      } else if (field.regex.test(value) && field.isRequired) {
        console.log('regex one field', value, field.regex);
        field.isValid = true;
        console.log('Valid 1');
      } else if (!field.isRequired) {
        field.isValid = true;
        console.log('Valid 2');
      } else {
        field.isValid = false;
        console.log('invalid');
      }

      if (!field.isValid) {
        return false;
      } else {
        return true;
      }
    },
  },
};

module.exports = {
  hasPermission(user, permId) {
    if (Object.keys(user).length !== 0) {
      return user.permissions?.includes(permId);
    } else {
      return false;
    }
  },

  checkVitalleoOrRegularOrder(isVitalleoOrder, isVitalleoUser) {
    if (!isVitalleoOrder) {
      return true;
    }
    return isVitalleoUser;
  },
};

// import vue from 'vue';
import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const tvToken = 'a29288cf-12c7-40ae-a340-a97bf825cb75';
const stripeKey = 'pk_test_6KGf6WilZ4g1uaOTFjkeGlQb00wDSznfSy';

const store = createStore({
  state: {
    IS_LAUNCHED: true,
    // apis
    sideMenuCollapsePattern: {},
    token: undefined,
    tvToken,
    stripeKey,
    user: {},
    searchKey: '',
    currLab: {},
    product: {},
    // //////////////
    // tv app
    // //////////////
    tvUser: {},
    tvUserToken: undefined,
    tvUserId: '',
    prod: {},
    currImage: '',
    cart: [],
    cartMap: {},
    location: {
      latitude: undefined,
      longitude: undefined,
    },
    billingAddress: {},
    shippingAddress: {},
    order: {},
    keys: {
      ENTER: 13,
      RIGHT: 39,
      LEFT: 37,
      UP: 38,
      DOWN: 40,
      BACK: [8, 461],
    },
    recieptUrl: '',
    isGuideVisited: false,
    toastMsg: {},
    isVitalleoOrder: false,

    permissions: {
      clinic: 27,
      clinic_orders: 29,
      doctor_npi_field: 31,
      qr_scanner: 30,
      vitalleo: 25,
      vitalleo_orders: 32,
      vitalleo_user: 28,
      patient_email: 33,
      return_tracking_field: 34,
    },
    qrScreenTabIndex: 0,
    qrRes: undefined,
    isAffiliate: false,
    timeOutId: undefined,
    isLoginModal: false,
    shippingAddressStatus: '',
    isSideMenuOpen: false,
  },
  mutations: {
    // /////////////////
    // tv app
    // /////////////////
    setGuidVisitedStatus(state, bool) {
      state.isGuideVisited = bool;
    },
    setRecieptUrl(state, url) {
      state.recieptUrl = url;
    },
    addToOrder(state, obj) {
      const key = obj.key;
      const value = obj.value;
      state.order[key] = value;
    },
    setTvUserId(state, id) {
      state.tvUserId = id;
    },
    setTvUser(state, user) {
      state.tvUser = user;
    },
    setTVUserToken(state, token) {
      state.tvUserToken = token;
    },
    setProd(state, prod) {
      state.prod = prod;
      state.currImage = prod.images[0];
    },
    setCurrImage(state, image) {
      state.currImage = image;
    },
    setCart(state, prod) {
      state.cartMap[prod.title] = state.cart.length;
      state.cart.push(prod);
    },
    // setCartMap(state, prodTitle) {
    //   state.cartMap[prodTitle] = state.cart.length;
    // },
    deleteCartItem({ cart, cartMap }, title) {
      const index = cart.findIndex((item) => item.title === title);
      console.log('index:', index);
      vue.delete(cartMap, title);
      cart.splice(index, 1);
      cart.forEach((cartItem, index) => {
        vue.set(cartMap, cartItem.title, index);
      });
      console.log('cartMap:', cartMap);
      console.log('cart:', cart);
    },
    clearCart(state) {
      state.cart = [];
      state.cartMap = {};
    },
    setLocation(state, location) {
      state.location = location;
    },
    setBillingAddress(state, address) {
      state.billingAddress = address;
    },
    setShippingAddress(state, address) {
      state.shippingAddress = address;
    },
    updateCartQty(state, { title, qty }) {
      const index = state.cart.findIndex((item) => item.title === title);
      state.cart[index].qty = qty;
      state.cart[index].newPrice = parseFloat(state.cart[index].sellPrice) * parseInt(state.cart[index].qty);
      console.log('cart item:', state.cart[index]);
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    // /////////////////
    // rx admin
    // /////////////////
    setCollapsePattern(state, pattern) {
      state.sideMenuCollapsePattern[pattern.key] = pattern.isCollapsed;
    },
    setToken(state, token) {
      state.token = token;
    },
    saveUser(state, user) {
      state.user = user;
    },
    setSearchKey(state, searchKey) {
      state.searchKey = searchKey;
    },
    setCurrLab(state, currLab) {
      state.currLab = currLab;
    },
    setProduct(state, product) {
      state.product = product;
    },
    setToastMsg(state, message) {
      state.toastMsg = message;
      let secs = undefined;
      if (message.type === 'success') {
        secs = 2000;
      } else {
        secs = 7000;
      }
      state.timeOutId = setTimeout(() => {
        state.toastMsg = {};
        clearTimeout(state.timeOutId);
      }, secs);
    },
    removeToastMsg(state) {
      state.toastMsg = {};
      clearTimeout(state.timeOutId);
    },
    setVitalleoOrder(state, boolean) {
      state.isVitalleoOrder = boolean;
    },

    setQrScreenTabIndex(state, index) {
      console.log('vuex', index);
      state.qrScreenTabIndex = index;
    },
    setQrResponse(state, data) {
      state.qrRes = data;
    },
    setAffiliate(state, boolean) {
      state.isAffiliate = boolean;
    },
    setLoginModal(state, boolean) {
      state.isLoginModal = boolean;
    },
    setShippingAddressStatus(state, status) {
      state.shippingAddressStatus = status;
    },
    setSideMenu(state, isBool) {
      state.isSideMenuOpen = isBool;
    },
  },
  actions: {
    setQrScreenTabIndex({ commit }, index) {
      commit('setQrScreenTabIndex', index);
    },
  },
  plugins: [vuexLocal.plugin],
});

export default store;

import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store/vuexIndex';
import Axios from 'axios';
import './assets/css/main.css';
import './assets/tailwind.css';
import QrcodeVue from 'qrcode.vue';
import config from './.env.js';
import { createPinia } from 'pinia';
import directives from './customDirectives.js';

const pinia = createPinia();
const app = createApp(App);
const baseUrl = config.baseUrl;

app.config.globalProperties.productionTip = false;

// console.log = function log() {};

// const tvToken = 'a29288cf-12c7-40ae-a340-a97bf825cb75';
// const stripeKey = 'pk_test_6KGf6WilZ4g1uaOTFjkeGlQb00wDSznfSy';

app.config.globalProperties.$env = {
  IS_PROD: config.IS_PROD,
};

app.config.globalProperties.$http = Axios.create({
  baseURL: baseUrl,
  // timeout: 10000,
});

app.provide('$http', app.config.globalProperties.$http);

app.config.globalProperties.$httpTv = Axios.create({
  baseURL: config.baseUrlTv,
  // timeout: 10000,
});

app.provide('$httpTv', app.config.globalProperties.$httpTv);

app.config.globalProperties.$date = {
  getDateWithTime(dateTimeStringTZ) {
    let date = new Date(dateTimeStringTZ).toString();
    return date.substring(4, 21);
  },
};

app.use(directives.customMouseoverDirective);
app.use(directives.customClickDirective);

app.config.globalProperties.$helpers = {
  nonRegex: {
    pin: /[^0-9]+/g,
    mobile: /[^0-9]+/g,
    numbersOnly: /[^0-9]+/g,
  },
  regex: {
    firstname: /^[a-zA-Z -]{2,}$/,
    lastname: /^[a-zA-Z -]{1,}$/,
    fullname: /^[a-zA-Z -]{3,}$/,
    // eslint-disable-next-line
    email: /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/,
    // eslint-disable-next-line
    housename: /^[a-zA-Z0-9/,./ ]{2,}$/,
    address: /^[a-zA-Z0-9/,.&/ ]{2,}$/,
    street: /^[a-zA-Z0-9/, ]{4,}$/,
    city: /^[a-zA-Z& ]{3,}$/,
    state: /^[a-zA-Z& ]{3,}$/,
    stateTwoChar: /^[a-zA-Z& ]{2,}$/,
    country: /^[a-zA-Z ]{2,}$/,
    pin: /^[A-Z0-9 ]{4,}$/,
    mobile: /^[0-9/+]{10}$/, // exact 10 characters only
    generalText: /^.{3,}$/,
    shortText: /^.{1,}$/,
    number: /^[0-9]{1,}$/,
    quantity: /^[0-9]{1,}$/,
    text: /^[a-zA-Z ]{3,}$/,
    gst: /^[0-9]{1,}$/,
    message: /^.{3,}$/,
    card: {
      numberOne: /^[0-9/+]{16}$/,
      numberTwo: /^[0-9/+]{15}$/,
      cvc: /^[0-9/+]{3}$/,
    },
  },
  qrUrl: config.qrUrl,
  baseUrl: config.baseUrl,
  url: config.url,
};

const getFromLocal = (key, defaultVal) => {
  let value;
  if (localStorage[key]) {
    try {
      value = JSON.parse(localStorage[key]);
    } catch (e) {
      delete localStorage[key];
    }
  }
  return value || defaultVal;
};

app.config.globalProperties.$user = {
  stripeInit() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://js.stripe.com/v3/';
    document.head.appendChild(script);
    return script;
  },

  // googleInit() {
  //   const meta = document.createElement('meta');
  //   meta.name = 'google-signin-client_id';
  //   meta.content = '1084215716364-v4t0nd7k8cgtluhieqmjbnmhbpfv89fd.apps.googleusercontent.com'; //rx-hometest client id
  //   document.head.appendChild(meta);
  //   const script = document.createElement('script');
  //   script.async = true;
  //   script.src = 'https://apis.google.com/js/platform.js';
  //   document.head.appendChild(script);
  //   return script;
  // },
  googleInit() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://accounts.google.com/gsi/client';
    document.head.appendChild(script);
    return script;
  },
  googleSignOut(isDifferentUser) {
    const script = this.googleInit();
    script.onload = () => {
      console.log('script loaded');
      // eslint-disable-next-line
      const localGapi = gapi;
      localGapi.load('auth2', () => {
        localGapi.auth2.init().then(() => {
          const auth2 = localGapi.auth2.getAuthInstance();
          if (isDifferentUser) {
            auth2.disconnect();
          }
          if (auth2.isSignedIn.get()) {
            auth2.signOut().then(() => {
              console.log('User signed out from google.');
            });
          }
        });
      });
    };
  },

  get user() {
    const value = getFromLocal('rx_new_user', {});
    if (!value) {
      localStorage.rx_new_user = JSON.stringify({});
    }
    return value;
  },
  set user(value) {
    localStorage.rx_new_user = JSON.stringify(value);
  },

  get token() {
    return localStorage.rx_token;
  },
  set token(value) {
    localStorage.rx_token = value;
  },

  get localEmail() {
    return localStorage.email;
  },
  set localEmail(value) {
    localStorage.email = value;
  },

  get currQrCode() {
    return localStorage.qr_code;
  },
  set currQrCode(value) {
    localStorage.qr_code = value;
  },
  hasPermission(id) {
    return this.user?.permissions?.includes(id);
  },
  // isDifferentUser
  logout() {
    // this.googleSignOut(true); // hard-coding so that google login doesn't auto-login after logout
    localStorage.removeItem('rx_token');
    localStorage.removeItem('rx_new_user');
  },
};

app.use(QrcodeVue).use(pinia).use(store).use(router).mount('#app');
